.LoginPage {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	max-width: 1050px;

	margin: 0 auto;
	width: 100%;
	transform: translate(-50%, -50%);
}

.LoginPage-leftAside {
	position: relative;
	flex: 1;
	border-top-left-radius: 2rem;
	border-bottom-left-radius: 2rem;
	background-color: #f8deca;
}

.LoginPage-illustration {
	position: absolute;
	bottom: 2rem;
	left: 50%;
	transform: translateX(-50%);
}

.LoginPage-rightMain {
	display: flex;
	flex: 2;
	align-items: center;
	justify-content: center;
	padding: 7rem 0;
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	background-color: #fff;
}

.LoginPage-form {
	display: flex;
	flex-direction: column;
	max-width: 325px;
	width: 100%;
}

.LoginPage-fieldset {
	border: none;
	padding: 0;
	width: 100%;
}

.LoginPage-ficLogo {
	width: 30rem;
	max-width: 100%;
	height: auto;
	margin-bottom: 3rem;
}

.MuiPaper-root.MuiPaper-rounded {
	border-radius: 10px;
}

@media screen and (max-width: 68rem) {
	.LoginPage {
		height: 100%;
	}

	.LoginPage-leftAside {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: unset;
	}

	.LoginPage-rightMain {
		width: 100%;
		background: transparent;
	}

	.LoginPage-illustration {
		display: none;
	}

	.LoginPage-ficLogo {
		margin-bottom: 1.5rem;
	}

	.LoginPage-form {
		max-width: 500px;
		background-color: #fff;
		border-radius: 30px;
		padding: 100px;
	}
}

@media screen and (max-width: 37rem) {
	.LoginPage-form {
		max-width: 400px;
		padding: 50px;
	}
}

@media screen and (max-width: 28rem) {
	.LoginPage-form {
		max-width: 95%;
		padding: 40px;
	}
}
