.CorporationSwitcher {
	position: relative;
}

.CorporationSwitcher.is-collapsed {
	min-width: 20rem;
}

.CorporationSwitcher-button {
	webkit-appearance: none;
	border: none;
	background: transparent;
	cursor: pointer;
	padding: 0.5rem 0.25rem 0 0.75rem;
}

.is-collapsed .CorporationSwitcher-button {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	width: 100%;
}

.CorporationSwitcher-listWrapper {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 0.5rem;
	z-index: 2;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background: white;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}

.CorporationSwitcher-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.CorporationSwitcher-listItem:not(:last-child) .CorporationSwitcher-link {
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1) inset;
}

.CorporationSwitcher-link {
	display: block;
	padding: 0.5rem;
	width: 100%;
	color: black;
	text-decoration: none;
}

.CorporationSwitcher-link:hover,
.CorporationSwitcher-link:focus {
	background: #f8f6f3;
}

.CorporationSwitcher-linkHint {
	padding-left: 0.25rem;
	color: #494949f2;
	font-size: 0.9rem;
}
